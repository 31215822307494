<template>
    <FalconFrame v-if="showEventPage">
        <WtcChannel :channel-name="currentSledChannelName">
            <template #slot__result="{ channelContent }">
                <BsnContent :current-channel="channelContent" />
            </template>

            <template #slot__not-initialized>
                <FalconPlaceHolder msg="no-data"></FalconPlaceHolder>
            </template>
        </WtcChannel>
    </FalconFrame>
    <FalconPlaceHolder v-else msg="no-event" />
</template>

<script setup lang="ts">
import { FalconFrame, FalconPlaceHolder, useFalconStore, useSledLiveChannelName } from "@swisstiming/falcon-core";
import { WtcChannel } from "@swisstiming/webtec-kit";
import { isEmpty } from "lodash-es";
import { computed } from "vue";

import BsnContent from "./views/BsnContent.vue";
const falconStore = useFalconStore();
const currentEventData = computed(() => falconStore.data?.currentEventData);
const currentSledChannelName = computed(() => useSledLiveChannelName(falconStore.config.tournamentId));

// if url-param 'raceId' is provided, it will be checked against the event-id
// if they dont match a no race available information is displayed
const showEventPage = computed(
    () => isEmpty(falconStore.qParams.raceId) || falconStore.qParams.raceId === currentEventData.value.EventId
);
</script>
<style>
body {
    @apply bg-surface;
}
</style>
