import "./styles/styles.scss";

import { falconPlugin } from "@swisstiming/falcon-core";
import { SportCodes } from "@swisstiming/webtec-kit";
import PrimeVue, { PrimeVueConfiguration } from "primevue/config";
import ProgressBar from "primevue/progressbar";
import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

import App from "./App.vue";
import BsnApp from "./views/BsnApp.vue";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [{ path: "/", component: BsnApp }]
});

const app = createApp(App)
    .use(falconPlugin, {
        title: "Bobsleigh/Skeleton Web Results",
        router,
        storeOptions: {
            sportCode: SportCodes.BOBSLEIGH_SKELETON,
            initOptions: {
                withCurrentEvent: true,
                withCisConfig: true,
                withSeason: true
            },
            iframeIntegration: {
                required: false,
                domains: [".ibsf.org", "ibsf.stage.infrontams.tv", "omegatiming.com"]
            }
        }
    })
    .use<PrimeVueConfiguration>(PrimeVue, {
        unstyled: true
    });

app.component("ProgressBar", ProgressBar);
app.mount("#app");
