import "./styles/styles.scss";

import { falconPlugin } from "@swisstiming/falcon-core";
import { SportCodes } from "@swisstiming/webtec-kit";
import PrimeVue, { PrimeVueConfiguration } from "primevue/config";
import ProgressBar from "primevue/progressbar";
import { createApp } from "vue";

import App from "./App.vue";

const app = createApp(App)
    .use(falconPlugin, {
        title: "Bobsleigh/Skeleton Web Results",
        storeOptions: {
            sportCode: SportCodes.BOBSLEIGH_SKELETON,
            initOptions: {
                withCurrentEvent: true,
                withCisConfig: true,
                withSeason: true
            }
        }
    })
    .use<PrimeVueConfiguration>(PrimeVue, {
        unstyled: true
    });

app.component("ProgressBar", ProgressBar);
app.mount("#app");
