<template>
    <div
        v-if="displayNextUp"
        class="theme--comingup max-tablet:rounded-none max-tablet:m-0 max-tablet:w-full max-tablet:max-w-full bg-brand-secondary/80 font-normal_light relative m-2 max-w-80 rounded-lg p-2 text-white"
    >
        <div class="text-xs uppercase">
            {{ showNextEvent ? $getStrByLng().LABEL_NEXT_EVENT : $getStrByLng().LABEL_COMING_UP }}
        </div>
        <template v-if="showNextEvent">
            <div class="font-normal_regular mt-2">{{ nextEvent?.Header1 }}</div>
            <div class="text-sm leading-none text-white/60">{{ nextEventLocationHeader }}</div>
        </template>
        <template v-else>
            <div
                v-if="competitionIconImg"
                class="absolute bottom-0 right-3 size-8 bg-white"
                style="mask-size: 100%; -webkit-mask-repeat: no-repeat; mask-repeat: no-repeat; mask-position: center"
                :style="competitionIconImg"
            ></div>
            <div class="font-normal_regular mt-2 flex flex-wrap text-sm leading-none">
                {{ nextUnit?.EventName }} {{ nextUnit?.UnitName }}
            </div>

            <div class="text-xs text-white/60">
                {{ standingDataLocation?.Title }}
            </div>
            <div class="mr-12 mt-1 flex flex-wrap text-xs text-white/60">
                <div class="font-normal_bold mr-1">
                    <WtcDateTime :date="nextUnit?.StartTime" format="DD.MM.YYYY"></WtcDateTime>,
                </div>
                <div><WtcDateTime :date="nextUnit?.StartTime" format="HH:mm"></WtcDateTime></div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useFalconStore } from "@swisstiming/falcon-core";
import {
    getCurrentChannel,
    ICurrentUnitsChannel,
    ScheduleStatus,
    useChannel,
    WtcDateTime
} from "@swisstiming/webtec-kit";
import { computed } from "vue";

import bobIcon from "../../assets/images/icon-bob.svg";
import monoBobIcon from "../../assets/images/icon-bob-1.svg";
import twoBobIcon from "../../assets/images/icon-bob-2.svg";
import skeletonIcon from "../../assets/images/icon-skeleton.svg";
import { BsnCompetitionTypes, BsnDisciplineTypes } from "../../enums/bsn.enums";

const falconStore = useFalconStore();

const currentChannelName = computed(() => getCurrentChannel(falconStore.config.tournamentId));
const { content: currentChannel } = useChannel<ICurrentUnitsChannel>(currentChannelName);
const seasonChannel = computed(() => falconStore.data?.seasonData);

const showNextEvent = computed(() => falconStore?.data?.currentEventData?.Status === ScheduleStatus.Finished);
const displayNextUp = computed(() => nextEvent.value || nextUnit.value);

const nextUnit = computed(() => currentChannel.value?.Next?.at(0));
const standingDataLocation = computed(() => falconStore.data?.standingData?.Locations?.[nextUnit?.value?.Location]);

const competitionIconImg = computed(() => {
    let imgUrl: string;
    const type = nextUnit?.value?.Stats?.Type as BsnCompetitionTypes;
    const discipline = nextUnit?.value?.Rsc?.Discipline as BsnDisciplineTypes;
    switch (type) {
        case BsnCompetitionTypes.SKELETON:
            imgUrl = skeletonIcon;
            break;
        case BsnCompetitionTypes.MONO:
            imgUrl = monoBobIcon;
            break;
        case BsnCompetitionTypes.TWO:
            imgUrl = twoBobIcon;
            break;
        case BsnCompetitionTypes.FOUR:
            imgUrl = bobIcon;
            break;
        case BsnCompetitionTypes.RELAY:
            if (discipline === BsnDisciplineTypes.SKELETON) {
                imgUrl = skeletonIcon;
            }
            break;
        default:
            break;
    }
    if (imgUrl) {
        return `mask-image: url("${imgUrl}"); webkit-mask-image: url("${imgUrl}")`;
    }
    return undefined;
});

const nextEvent = computed(() => {
    const currentSeasonEvent = seasonChannel.value?.Events?.[falconStore.config.tournamentId];
    return Object.values(seasonChannel.value?.Events ?? {})?.find(
        (event) => event?.ListIndex === currentSeasonEvent?.ListIndex - 1
    );
});

const nextEventLocationHeader = computed(() => {
    const header2 = nextEvent.value?.Header2;
    const header3 = nextEvent.value?.Header3;

    return header2 && header3 ? `${header2} / ${header3}` : header2 || header3 || "";
});
</script>
