<template>
    <ul class="flex flex-wrap">
        <BsnHighlightedValue
            v-for="run in currentChannel.Current?.Runs"
            :key="run.Id"
            :displayed-value="run.Result"
            :label="run.Name"
        ></BsnHighlightedValue>
        <BsnHighlightedValue
            :displayed-value="currentChannel.Current?.Result"
            :label="$getStrByLng().LABEL_TOTAL"
        ></BsnHighlightedValue>
        <BsnHighlightedValue
            :displayed-value="currentIntermediate?.Tendency"
            :label="$getStrByLng().LABEL_TOTAL_GAP"
        ></BsnHighlightedValue>
        <BsnHighlightedValue
            :displayed-value="currentChannel.Current?.Rank"
            :label="$getStrByLng().LABEL_RANK"
        ></BsnHighlightedValue>
    </ul>
</template>

<script setup lang="ts">
import { ISledLiveChannel } from "@swisstiming/falcon-core";
import { computed } from "vue";

import BsnHighlightedValue from "./elements/BsnHighlightedValue.vue";

const props = defineProps<{
    currentChannel?: ISledLiveChannel;
}>();

const currentIntermediate = computed(() => {
    const currentIntIndex = props.currentChannel?.Stats?.CurSplit;
    return props.currentChannel?.Current?.Intermediate?.at(currentIntIndex);
});
</script>

<style lang="scss"></style>
