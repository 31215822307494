import { useFalconStore } from "@swisstiming/falcon-core";
import { computed, Ref } from "vue";

export const useIrmName = (irm: Ref<string>) => {
    return computed(() => {
        if (!irm?.value) {
            return;
        }
        const falconStore = useFalconStore();
        return falconStore.data.standingData?.Statusses?.[irm?.value]?.Code;
    });
};
