export enum BsnCompetitionTypes {
    SKELETON = "Single",
    MONO = "Mono",
    TWO = "Team2",
    FOUR = "Team4",
    RELAY = "Relay"
}

export enum BsnDisciplineTypes {
    SKELETON = "SKN"
}

export enum BsnProfiles {
    OMEGA = "omega"
}

export enum BsnCompetitorStatus {
    AT_START = "AT_START",
    ON_TRACK = "ON_TRACK",
    FINISHED = "FINISHED"
}

export enum BsnRecordIds {
    SR = "SR",
    TR = "TR"
}
