<template>
    <table class="w-full">
        <thead>
            <tr>
                <!-- rank -->
                <th class="!sticky left-0 w-12 min-w-12">{{ $getStrByLng().LABEL_RANK }}</th>
                <!-- bib -->
                <th class="w-12 min-w-12">{{ $getStrByLng().LABEL_BIB }}</th>
                <!-- noc -->
                <th class="w-10 min-w-10"></th>
                <!-- name -->
                <th class="text-left">
                    {{ $getStrByLng().LABEL_NAME }}
                </th>
                <!-- heat -->
                <th v-for="run in timingChannel.Runs" :key="run.Id" class="w-36 min-w-36">
                    {{ run.Name }}
                </th>
                <!-- current run -->
                <th class="w-36 min-w-36">
                    {{ currentRunLabel }}
                </th>
                <!-- penalty -->
                <th v-if="showSinglePenalty" class="w-20 min-w-20">
                    {{ $getStrByLng().LABEL_PENALTY }}
                </th>
                <!-- total -->
                <th class="!sticky right-0 w-24 min-w-24 text-right">{{ $getStrByLng().LABEL_TOTAL_TIME }}</th>
            </tr>
        </thead>
        <WtcTransitionGroup v-if="sortedResultList?.length > 0" name="list-sort" tag="tbody">
            <tr
                v-for="competitor in sortedResultList"
                :key="competitor.Id"
                :class="getTWHighlightCompetitorClass(competitor, currentChannel?.Current?.Id)"
            >
                <FalconRankIrm class="!sticky left-0" :competitor="competitor" />
                <td>{{ competitor.Bib }}</td>
                <td>
                    <FalconFlag :flag-code="competitor.Nationality"></FalconFlag>
                </td>
                <td class="min-w-52 text-wrap text-left leading-none">
                    <WtcName :competitor-name="competitor"></WtcName>
                </td>
                <td v-for="run in competitor.Runs" :key="run.Id">
                    <div class="grid grid-cols-2 gap-1">
                        <div class="font-normal_bold leading-none">
                            <div>{{ run.SectorResult }}</div>
                            <div v-if="run.SectorRank" class="font-normal_regular text-xs font-normal">
                                {{ addBrackets(run.SectorRank, "[") }}
                            </div>
                        </div>
                        <div class="font-normal_bold leading-none">
                            <div>{{ run.Result }}</div>
                            <div class="font-normal_regular text-xs font-normal">
                                {{ addBrackets(run.Rank, "[") }}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <!-- This one always represents the current run -->
                    <div
                        class="grid gap-1"
                        :class="{
                            'grid-cols-1': timingChannel?.RunCount == 0,
                            'grid-cols-2': timingChannel?.RunCount > 0
                        }"
                    >
                        <div class="run-start font-normal_bold leading-none">
                            <div class="relative">
                                <BsnRecordBadge
                                    class="bg-surface-highlightLight text-content-highlight absolute -left-2.5 p-1 text-xs"
                                    :record-string="getStartRecordString(competitor.Record)"
                                ></BsnRecordBadge>
                                <div>{{ competitor.Stats?.SectorResult }}</div>
                                <div class="font-normal_regular text-xs font-normal">
                                    {{ addBrackets(competitor.Stats?.SectorRank, "[") }}
                                </div>
                            </div>
                        </div>
                        <div v-if="timingChannel?.RunCount > 0" class="run-time font-normal_bold relative leading-none">
                            <BsnRecordBadge
                                class="bg-surface-highlightLight text-content-highlight absolute -left-2.5 p-1 text-xs"
                                :record-string="getTrackRecordString(competitor.Record)"
                            ></BsnRecordBadge>
                            <div>
                                <div>{{ competitor.Intermediate?.at(-1)?.Result }}</div>
                                <div class="font-normal_regular text-xs font-normal">
                                    {{ addBrackets(competitor.Intermediate?.at(-1)?.Rank, "[") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td v-if="showSinglePenalty" class="text-content-negative text-right">
                    {{ competitor.Stats?.PenaltyTime }}
                </td>
                <td class="!sticky right-0 text-right">
                    {{ competitor.Behind || competitor.Result }}
                </td>
            </tr>
        </WtcTransitionGroup>
        <tbody v-else>
            <tr>
                <WtcPlaceholder
                    class="text-brand font-normal_bold uppercase"
                    colspan="100"
                    tag="td"
                    :text="$getStrByLng().MESSAGE_NO_DATA_AVAILABLE"
                />
            </tr>
        </tbody>
    </table>
</template>
<script setup lang="ts">
import {
    FalconFlag,
    FalconRankIrm,
    ISledLiveChannel,
    isPropertyTruthy,
    useSledTimingChannel
} from "@swisstiming/falcon-core";
import {
    addBrackets,
    getStrByLng,
    getTWHighlightCompetitorClass,
    WtcName,
    WtcPlaceholder,
    WtcTransitionGroup
} from "@swisstiming/webtec-kit";
import { computed } from "vue";

import { getStartRecordString, getTrackRecordString } from "../utils/bsn-data.utils";
import BsnRecordBadge from "./elements/BsnRecordBadge.vue";

const props = defineProps<{
    currentChannel: ISledLiveChannel;
}>();

const { sortedResultList, timingChannel } = useSledTimingChannel(computed(() => props.currentChannel?.Rsc?.Value));

const currentRunLabel = computed(() =>
    timingChannel.value?.RunCount > 0 ? getStrByLng().LABEL_CURRENT_RUN : getStrByLng().LABEL_START
);
const hasCompetitionPenalties = computed(() => isPropertyTruthy(timingChannel.value?.Stats?.HasPenalties));
const showSinglePenalty = computed(() => hasCompetitionPenalties.value);
</script>
<style lang="scss">
tr.current-competitor {
    > td {
        @apply bg-surface-highlightLight !important;

        &:first-child {
            @apply before:h-full before:border-l-4 before:border-surface-highlight before:content-['*'] before:absolute before:left-0 before:top-0 before:text-transparent relative;
        }
    }
}
</style>
