<template>
    <div class="top-speed">
        <div class="theme--box-header font-normal_bold p-2 text-sm">{{ $getStrByLng().LABEL_SPEED }}</div>
    </div>
    <div class="flex flex-col gap-2">
        <div>
            <div
                class="theme--table-head bg-brand-secondary font-normal_light h-5 px-2 py-1 text-xs uppercase leading-none text-white"
            >
                {{ $getStrByLng().LABEL_TOP_SPEED }}
            </div>

            <div class="bg-surface-alternate flex flex-col gap-1 p-2 text-sm">
                <div class="font-normal_bold">
                    {{ addSpeedUnitLabel(currentChannel.Current?.Stats?.TopSpeed_KMH) }}
                </div>
                <div class="font-normal_bold flex gap-2 text-xs">
                    <div>
                        <span class="font-normal_regular font-normal">{{ $getStrByLng().LABEL_BEST }}: </span
                        >{{ addSpeedUnitLabel(currentChannel.Stats?.BestTopSpeed_KMH) }}
                    </div>
                    <div class="theme--best text-content-secondary">{{ currentChannel.Stats?.BestTopSpeed_Name }}</div>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <div
                class="theme--table-head bg-brand-secondary font-normal_light h-5 px-2 py-1 text-xs uppercase leading-none text-white"
            >
                {{ $getStrByLng().LABEL_ACCLERATION_0_TO_30 }}
            </div>
            <div class="bg-surface-alternate flex flex-col gap-1 p-2">
                <div class="flex items-center">
                    <ProgressBar
                        :pt="{
                            root: 'bg-surface-secondary flex-1 overflow-hidden rounded-md border border-black/10',
                            value: 'theme--progress h-2 transition-all bg-gradient-to-r from-amber-600 to-amber-400'
                        }"
                        :show-value="false"
                        :value="timeToHit30ProgressComp"
                    >
                    </ProgressBar>
                    <div v-if="currentChannel.Current?.Stats?.TimeToHit30" class="font-normal_bold ml-1 text-sm">
                        {{ addTimeUnitLabel(currentChannel.Current?.Stats?.TimeToHit30) }}
                    </div>
                </div>
                <div class="mt-1 flex flex-col">
                    <div class="font-normal_bold text-xs">
                        <span class="font-normal_regular font-normal">{{ $getStrByLng().LABEL_BEST }}: </span
                        ><span class="theme--best text-content-secondary">{{
                            currentChannel.Stats?.BestTimeToHit30_Name
                        }}</span>
                    </div>
                    <div class="flex items-center">
                        <ProgressBar
                            :pt="{
                                root: 'bg-surface-secondary flex-1 overflow-hidden rounded-md border border-black/10',
                                value: 'theme--progress h-2 transition-all bg-gradient-to-r from-amber-600 to-amber-400'
                            }"
                            :show-value="false"
                            :value="timeToHit30ProgressBest"
                        >
                        </ProgressBar>
                        <div v-if="currentChannel.Stats?.BestTimeToHit30" class="font-normal_bold ml-1 text-sm">
                            {{ addTimeUnitLabel(currentChannel.Stats?.BestTimeToHit30) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ISledLiveChannel } from "@swisstiming/falcon-core";
import { getStrByLng } from "@swisstiming/webtec-kit";
import { computed, ref } from "vue";
const props = defineProps<{
    currentChannel: ISledLiveChannel;
}>();

const addSpeedUnitLabel = (speedValue: string) => (speedValue ? `${speedValue} ${getStrByLng().LABEL_SPEED_UNIT}` : "");
const addTimeUnitLabel = (timeValue: string) => (timeValue ? `${timeValue}${getStrByLng().LABEL_SECONDS_UNIT}` : "");

const calculateTimeToHit30Progress = (calcBestTimeProgress = false) => {
    const compTime = props.currentChannel?.Current?.Stats?.TimeToHit30;
    const bestTime = props.currentChannel?.Stats?.BestTimeToHit30;

    if (compTime && bestTime) {
        const compTimeNumber = parseFloat(compTime);
        const bestTimeNumber = parseFloat(bestTime);

        const compareCondition = calcBestTimeProgress
            ? // current-competitor has faster acceleration (less time spend to hit 30) then best-competitor: current-competitor 100-x, best-competitor 100
              bestTimeNumber > compTimeNumber
            : // current-competitor has slower acceleration (more time spend to hit 30) then best-competitor: current-competitor 100, best-competitor 100-x
              compTimeNumber > bestTimeNumber;

        return compareCondition
            ? 100
            : (100 * Math.min(compTimeNumber, bestTimeNumber)) / Math.max(compTimeNumber, bestTimeNumber);
    } else if (calcBestTimeProgress ? bestTime : compTime) {
        return 100;
    } else {
        return 0;
    }
};

const timeToHit30ProgressComp = computed(() => calculateTimeToHit30Progress());

const count = ref(5);
setInterval(() => {
    count.value = count.value + 5;
}, 1500);

const timeToHit30ProgressBest = computed(() => calculateTimeToHit30Progress(true));
</script>

<style lang="scss">
.progress {
    /* Webkit-Browser */
    &::-webkit-progress-bar {
        @apply bg-transparent;
    }

    &::-webkit-progress-value {
        @apply transition-all rounded-md bg-gradient-to-r from-amber-600 to-amber-400 rounded-r-none;
    }

    /* Mozilla-Browser */
    &::-moz-progress-bar {
        @apply transition-all rounded-md bg-gradient-to-r from-amber-600 to-amber-400;
    }
}
</style>
