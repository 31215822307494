<template>
    <div class="flex flex-row items-center">
        <FalconFlag :flag-code="competitor?.Nationality"></FalconFlag>
        <span class="ml-2 text-sm">{{ competitor?.Bib }}</span>
        <WtcName class="font-normal_bold ml-2 text-lg" :competitor-name="competitor"></WtcName>
        <div v-if="isRelay" class="ml-2 flex flex-wrap items-center gap-2">
            <WtcName
                v-for="(member, index) in competitor.Children"
                :key="member.AthleteId"
                class="px-1 py-0.5 uppercase"
                :class="{
                    'bg-surface-highlightLight !text-content-highlight rounded': currentChildId === index
                }"
                :competitor-name="member"
            ></WtcName>
        </div>
    </div>
</template>
<script setup lang="ts">
import { FalconFlag, ISledCurrentCompetitor, useSledLiveChannel } from "@swisstiming/falcon-core";
import { WtcName } from "@swisstiming/webtec-kit";
import { computed } from "vue";

const props = defineProps<{
    competitor?: ISledCurrentCompetitor;
}>();

const { isRelay } = useSledLiveChannel();

const currentChildId = computed(() => props.competitor?.CurrentChildId);
</script>
